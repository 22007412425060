import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import "../components/fonts.css"
import "../components/global.css"
import favicon from "../components/favicon.png"

import Menu from "../components/Menu"
import Intro from "../components/Intro"
import Projects from "../components/Projects"
import Footer from "../components/Footer"

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

export default () => (
  <Container>
    <Helmet>
      <meta charSet='utf-8' />
      <title>David D. Ortiz | Product Designer</title>
      <link rel='icon' type='image/png' href={favicon} sizes='16x16' />
      <link rel='alternate' href='https://www.davidortiz.me' hreflang='en-us' />
      <link rel='canonical' href='https://www.davidortiz.me' />
      <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
      <meta name='description' content='Product Designer | UX, Front End & Deployment for Remote Teams' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />

      <meta property='og:title' content='Product Designer | UX, Front End & Deployment for Remote Startups.' />
      <meta property='og:site_name' content='Personal Portfolio 2019' />
      <meta property='og:description' content='Product Designer | UX, Front End & Deployment for Remote Startups.' />
    </Helmet>
    <Menu />
    <Intro />
    <Projects />
    <Footer />
  </Container>
)
