// Imports
import React from "react"
import styled from "styled-components"
import sig from "../components/sig.png"
import Social from "../components/Social"
import aboutBg from "../components/aboutBg.png"

const LandingIntro = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  overflow: hidden;
  background: #1c2532;
  .overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${aboutBg});
    background-size: cover;
    background-position: right;
    animation: fadeSemi 1.5s ease-in-out forwards, scaleUp 60s ease-in-out forwards;
    @media (max-width: 500px) {
      background-position: right;
    }
  }
  .social {
    position: relative;
    opacity: 0;
    padding: 20px 0;
    width: 400px;
    margin: 0;
    animation: fade 2s ease-in-out forwards, slideUp 800ms ease-in-out forwards;
    animation-delay: 200ms;
    @media (max-width: 500px) {
      width: 90%;
      margin: 0 auto;
    }
    a {
      &:first-child {
        padding-left: 0;
      }
    }
  }
  h1,
  h2,
  p {
    margin: 0;
  }
  p {
    font-size: 1.2em;
    padding: 4px 0;
    @media (max-width: 500px) {
      padding: 4px 0 30px;
      max-width: 300px;
      margin: 0 auto;
    }
  }
  h1,
  h2 {
    position: relative;
    font-family: "merri-bold";
    opacity: 0;
    animation: fade 1s ease-in-out forwards, slideUp 800ms ease-in-out forwards;
  }
  h1 {
    font-size: 3.6em;
    line-height: 100%;
    font-family: merri-bold;
    @media (max-width: 500px) {
      font-size: 3em;
    }
    @media (min-width: 1440px) {
      font-size: 5em;
    }
  }
  h2 {
    font-size: 1.7em;
    line-height: 120%;
    padding: 20px 0 30px;
    font-family: merri-light;
    animation-delay: 100ms;
    @media (max-width: 500px) {
      padding: 20px 20px 30px;
    }
  }
  p {
    position: relative;
    animation: fade 2s ease-in forwards, slideUp 500ms ease-in forwards;
    animation-delay: 250ms;
    opacity: 0;
    padding-bottom: 10px;
    font-family: merri-light;
  }
  .bio {
    position: absolute;
    bottom: 5%;
    left: 5%;
    width: 100%;
    @media (max-width: 500px) {
      left: inherit;
      text-align: center;
    }
  }

  img {
    position: relative;
    margin: 20px 0;
    width: 160px;
    opacity: 0;
    animation: fadeSemi 1s ease-in-out forwards, slideUp 800ms ease-in-out forwards;
    animation-delay: 350ms;
  }
`

function Intro() {
  return (
    <LandingIntro>
      <div className='overlay' />
      <div className='bio'>
        <h1>David D. Ortiz</h1>
        <h2>Product Designer of Digital Experiences</h2>
        <p className='about'>UX, Frontend &amp; Deployment for Remote Teams</p>
        <Social />
        <img src={sig} alt='Signature' />
      </div>
    </LandingIntro>
  )
}

export default Intro
